import React, { useEffect, useState, useRef } from 'react'

import css from "./navbar.module.css"
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import './Reservation.css'
import Loading from './Loading'
const Navbar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navbarRef = useRef(null);
    const contentRef = useRef(null);

    useEffect(() => {
        if (navbarRef.current && contentRef.current) {
            const navbarHeight = navbarRef.current.offsetHeight;
            contentRef.current.style.paddingTop = `${navbarHeight}px`;
        }
    }, []);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const toggleFullScreen = () => {

        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullScreen(false);
            }
        }
    };



    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;


    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); window.location.assign("/") };

    const checkuser = async () => {

        const inputValue = document.getElementById("exampleDropdownFormEmail1").value.trim();
        if (inputValue === "") {
            toast.error("Please Enter UserName...")
            document.getElementById("exampleDropdownFormEmail1").focus();
            return false
        }

        const inputValue2 = document.getElementById("exampleDropdownFormPassword1").value.trim();
        if (inputValue2 === "") {
            toast.error("Please Enter Password...")
            document.getElementById("exampleDropdownFormPassword1").focus();
            return false
        }

        try {

            setIsLoading(true)
            const nameQuery = `select * from shopmas where email = '${document.getElementById("exampleDropdownFormEmail1").value}' limit 1`;
            const nameResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQuery)}`);
            console.log(nameResponse.data.length)
            if (nameResponse.data.length == 0) {
                toast.error("Invalid Username..."); setIsLoading(false); document.getElementById("exampleDropdownFormEmail1").focus();
                return false;
            }

            const nameQueryPassword = `select * from shopmas where password = '${document.getElementById("exampleDropdownFormPassword1").value}' limit 1`;
            const nameQueryPasswordResp = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQueryPassword)}`);
            if (nameQueryPasswordResp.data.length == 0) {
                toast.error("Enter Valid Password..."); setIsLoading(false); document.getElementById("exampleDropdownFormPassword1").focus();
                return false;
            }


            await axios.get(`${hostlink}/shopmas/${encodeURIComponent(document.getElementById("exampleDropdownFormEmail1").value)}/${encodeURIComponent(document.getElementById("exampleDropdownFormPassword1").value)}`)
                .then((res) => {
                    localStorage.removeItem('MYPCKARTUSER');
                    localStorage.setItem('MYPCKARTUSER', JSON.stringify(res.data));
                    if (Array.isArray(res.data) && res.data.length === 0 && res.data.every(item => item === "black")) {

                        toast.error("Enter Valid username and password..")
                        setIsLoading(false);
                        document.getElementById("exampleDropdownFormEmail1").focus();
                    }
                    else {
                        toast.success("User Found..")
                        setInterval(() => {
                            window.location.assign("/")
                        }, 1000);
                    }
                })
                .catch(() => {
                    console.log("Data Not Found")
                })

        } catch (error) {

        }

    }

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const logoutdevice = () => {
        localStorage.removeItem('MYPCKARTUSER');
        window.location.assign("/")
    }

    useEffect(() => {
        const adjustPadding = () => {
            if (navbarRef.current && contentRef.current) {
                const navbarHeight = navbarRef.current.offsetHeight;
                contentRef.current.style.paddingTop = `${navbarHeight}px`;
            }
        };

        adjustPadding();
        window.addEventListener('resize', adjustPadding);

        return () => {
            window.removeEventListener('resize', adjustPadding);
        };
    }, []);


    useEffect(() => {

        const myPckartUser = localStorage.getItem('MYPCKARTUSER');
        if (myPckartUser && JSON.parse(myPckartUser).length === 0) {
            localStorage.removeItem('MYPCKARTUSER');
        }
        setIsLoggedIn(!!myPckartUser);

        const pathname = window.location.pathname;
        const parts = pathname.split('/');
        const pageName = parts[parts.length - 1];
        if (pageName == "Company_Create") { }
        else if (pageName == "RecoveryyPassword") { }
        else {
            if (!myPckartUser) {
                handleOpenModal("1", "1");
            }
        }

    }, []);

    return (
        <div id={css.menubar} ref={contentRef}>
            <nav ref={navbarRef} className="navbar navbar-expand-lg navbar-light bg-light shadow-sm" style={{ position: "fixed", top: 0, width: "100%", zIndex: 1000 }}>
                <div className="container-fluid bg-body-secondary shadow-lg" >
                    <a className="navbar-brand" href="/"><i class="fa-solid fa-house" ></i> <a className="show-on-mobile font-monospace" style={{ textDecoration: "none", color: "black" }} href='#' onClick={(e) => { handleOpenModal() }}> {localStorage.getItem('MYPCKARTUSER') && JSON.parse(localStorage.getItem('MYPCKARTUSER')).length > 0 ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].cname : 'Login'}</a></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ display: isModalOpen ? "none" : "" }}>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                            <li className="nav-item dropdown active">
                                <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Master
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/AccountMaster"><i class="fa-regular fa-address-card" style={{ width: "20px" }}></i > Account </a></li>
                                    <li><a className="dropdown-item" href="/FabMas"><i class="fa-brands fa-phabricator" style={{ width: "20px" }}></i> Fabricator</a></li>
                                    <li><a className="dropdown-item" href="/WasherMas"><i class="fa-solid fa-cart-flatbed-suitcase" style={{ width: "20px" }}></i > Washer </a></li>
                                    <li><a className="dropdown-item" href="/FinishingMas"><i class="fa-solid fa-check-to-slot" style={{ width: "20px" }}></i > Pressman </a></li>
                                    <li><a className="dropdown-item" href="/FinshingEntryOpening"><i class="fa-solid fa-cubes" style={{ width: "20px" }}></i > Opening </a></li>

                                    
                                </ul>
                            </li>
                            <li className="nav-item dropdown active" >
                                <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fa-solid fa-flag"></i> Report
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item" style={{ display: "inline-block", whiteSpace: "nowrap", fontFamily: "Bahnschrift Condensed" }}>
                                        <a className="dropdown-item" href="/FindLotDetail"><i class="fa-solid fa-circle-info" style={{ width: "20px" }}></i> Lot Details..</a>
                                        <a className="dropdown-item" href="/FindLot"><i class="fa-solid fa-magnifying-glass" style={{ width: "20px" }}></i> Pending Lot</a>
                                        <a className="dropdown-item" href="/Rpt_PurchaseReport"><i class="fa-solid fa-book" style={{ width: "20px" }}></i> Purchase Report</a>
                                        <a className="dropdown-item" href="/Rpt_PurchaseReport_GR"><i class="fa-solid fa-arrow-rotate-left" style={{ width: "20px" }}></i> GR Report</a>
                                        <a className="dropdown-item" href="/Rpt_CuttingReport"><i class="fa-solid fa-scissors" style={{ width: "20px" }}></i> Cutting Report</a>
                                        <a className="dropdown-item" href="/Rpt_FabricatorReport"><i class="fa-brands fa-phabricator" style={{ width: "20px" }}></i> Fabricator Report</a>
                                        <a className="dropdown-item" href="/Rpt_FinishingReport"><i class="fa-solid fa-check-to-slot" style={{ width: "20px" }}></i> Pressman Report</a>
                                        <a className="dropdown-item" href="/Rpt_WashReport"><i class="fa-solid fa-recycle" style={{ width: "20px" }}></i> Washing Report</a>
                                        <a className="dropdown-item" href="/GodwnStk"><i class="fa-solid fa-warehouse" style={{ width: "20px" }}></i> Godown Stock Report</a>
                                        <a className="dropdown-item" href="/Rpt_SaleReport"><i class="fa-solid fa-warehouse" style={{ width: "20px" }}></i>  Sale Report</a>
                                        <a className="dropdown-item" href="/Rpt_SaleReport_Return"><i class="fa-solid fa-rotate-right" style={{ width: "20px" }}></i>  Sales Return Report</a>
                                        <a className="dropdown-item" href="/Rpt_FinalStock"><i class="fa-solid fa-layer-group" style={{ width: "20px" }}></i> Final Stock Report</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href='/Purchase' className="nav-link active"> <i class="fa-solid fa-book"></i> Purchase </a>
                            </li>
                            <li className="nav-item">
                                <a href='/PurchaseGR' className="nav-link active"> <i class="fa-solid fa-arrow-rotate-left"></i> GR </a>
                            </li>


                            <li className="nav-item">
                                <a className="nav-link active" href='/Cutting'><i class="fa-solid fa-scissors"></i> Cutting Table</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href='/FabricatorEntry'><i class="fa-brands fa-phabricator"></i> Fabricator</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href='/WasherEntry'><i class="fa-solid fa-recycle"></i> Washer</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link active" href='/FinshingEntry'><i class="fa-solid fa-check-to-slot"></i>Finshing</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href='/SaleEntry'><i class="fa-solid fa-right-from-bracket"></i> Sale </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href='/SaleEntryReturn'><i class="fa-solid fa-rotate-right"></i> Sales Return</a>
                            </li>
                                                    
                        </ul>
                        <div className="d-flex" role="search">
                            <a style={{ textDecoration: "none", color: "blue" }} href='#' onClick={(e) => { handleOpenModal() }}> <i class="fa-solid fa-user"></i> {localStorage.getItem('MYPCKARTUSER') && JSON.parse(localStorage.getItem('MYPCKARTUSER')).length > 0 ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].cname : 'Login'}</a>
                        </div>
                    </div>
                </div>
            </nav>

            <Modal isOpen={isModalOpen} id={css.signinmodel} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content " overlayClassName="modal-overlay">
                <div>
                    {isLoggedIn ? (
                        <div id="logoutuser">
                            <h1 className=''><i class="fa-solid fa-right-from-bracket"></i> Logout </h1>
                            <br />
                            <h3 className='text-center'  >
                                <a href='/Passwordchange' class="btn btn-outline-dark"><i class="fa-solid fa-key"></i> Change Password </a> <br /><br />
                                <button onClick={() => { logoutdevice() }} class="btn btn-primary"><i class="fa-solid fa-lock"></i> Logout </button> &nbsp;&nbsp;
                                <button onClick={handleNoClick} class="btn btn-outline-dark"><i class="fa-solid fa-right-from-bracket"></i> Cancel</button>
                            </h3>
                        </div>
                    ) : (
                        <div id="loginuser">
                            <h1 className=''><i class="fa-solid fa-circle-user"></i> Log in </h1>
                            <div className="px-4 py-3 text-start" >
                                <div class="mb-3">
                                    <label for="exampleDropdownFormEmail1" class="form-label">User Name</label>
                                    <input type="email" class="form-control" id="exampleDropdownFormEmail1" placeholder="email@example.com" />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleDropdownFormPassword1" class="form-label">Password</label>
                                    <input type="password" class="form-control" id="exampleDropdownFormPassword1" placeholder="Password" />
                                </div>
                                <div class="mb-3" style={{ display: "flex" }}>
                                    <div class="form-check w-25">
                                        <input type="checkbox" class="form-check-input" id="dropdownCheck" />
                                        <label class="form-check-label" for="dropdownCheck">
                                            Remember
                                        </label>
                                    </div>
                                    <div class="form-check text-end w-75">
                                        <a href="/RecoveryyPassword" style={{ color: "blue" }}><i class="fa-solid fa-key"></i> forgot password</a>
                                    </div>

                                </div>
                                <h3 className='text-center'  >
                                    <button onClick={() => { checkuser() }} class="btn btn-primary"><i class="fa-solid fa-lock-open"></i> Log in </button> &nbsp;&nbsp;
                                    <button onClick={handleNoClick} class="btn btn-outline-dark"><i class="fa-solid fa-right-from-bracket"></i> Cancel</button>

                                </h3>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <h3 className='text-center'>
                                </h3>
                            </div>

                        </div>
                    )}
                </div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}

export default Navbar
