import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const Rpt_SaleReport_Return = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [earchMobNo, setSearchMobNo] = useState('');
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: 0,
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, deptcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
const [itemdetail, setitemdetail] = useState({ mobno: "", custname: "", custadd: "", custstate: "", avg: "", style: "", size: "", pcs: "", rawcode: 0, washqty: 0, Washername: "", Washercode: 0, rawname: "", barcode: "", sortno: "", billno: "", billdate: today.toISOString().split('T')[0], mtr: "", roll: "", qty: 1, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "" });
const [searchTerm, setSearchTerm] = useState("");
    const [filteredDataMob, setFilteredDataMob] = useState([]);
    const [mobileNo, setmobileNo] = useState([])
const handleSearch = (e) => {
        setitemdetail({ ...itemdetail, mobno: e.target.value });
        const queryMob = e.target.value.toLowerCase().trim(); // Trim input for whitespace issues
        setSearchTerm(queryMob); // Update search term state

        if (queryMob === "") {
            setSearchMobNo('');
            setFilteredDataMob([]); // Clear results if input is empty
        } else {
            console.log("Search Query:", queryMob); // Debug log

            const matches = mobileNo.filter((item) => {
                const mobnoMatch = item.mobno && item.mobno.toString().includes(queryMob);
                const custnameMatch = item.custname && item.custname.toLowerCase().includes(queryMob);
                const custaddMatch = item.custadd && item.custadd.toLowerCase().includes(queryMob);
                console.log("Checking Item:", item);
                return mobnoMatch || custnameMatch || custaddMatch;
            });
            console.log("Matches Found:", matches); // Debug log
            setFilteredDataMob(matches); // Update the filtered data state
        }
    };


    const handleSelect = (item) => {
        console.log("Selected:", item);
        // setitemdetail({ ...itemdetail, mobno: e.target.value });
        setSearchTerm(item.custname);
        setitemdetail({
            ...itemdetail,
            mobno: item.mobno,
            custname: item.custname,
            custadd: item.custadd,
            custstate: item.custstate
        })
        setSearchMobNo(item.custname);
        setFilteredDataMob([]);
    };
    const loaddata = async (e) => {
        try {
                if(earchMobNo!=''){
                    const billResponse = await axios.get(`${hostlink}/PurchaseMasSearch/6/${usershopid}/${fromdate}/${todate}/${earchMobNo}`);
                    const billData = billResponse.data;
                    setData(billResponse.data)
                }else{
                    const billResponse = await axios.get(`${hostlink}/PurchaseMasviewrpt/6/${usershopid}/${fromdate}/${todate}`);
                    const billData = billResponse.data;
                    setData(billResponse.data)
                }
                
            
        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    useEffect(() => {
        axios.get(`${hostlink}/MobileNoSearch/${usershopid}`)
            .then((Response) => {
                setmobileNo(Response.data);
            })
            .catch((Response) => { "data Not Found" })

    }, [])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Sale Return Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-6 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-book"></i> <span id='pagemode'> Sale Return Report</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" /> 
                    </div>
                    <div className='col-md-6'>
                    </div>
                    <div className='col-md-1 text-center'>
                       
                    <label htmlFor="todate" className="form-label">Mob No :</label>
                    </div>
                   
                    <div className="col-12 col-md-3">
                                    
                                    <input
                                        type="text"
                                        placeholder="Search Name..."
                                        className="form-control"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        id='Mobilenonew'
                                        autoComplete='offa'
                                    />
                                    {filteredDataMob.length > 0 ? (
                                        <ul
                                            style={{
                                                border: "1px solid #ccc",
                                                padding: "10px",
                                                listStyle: "none",
                                                maxWidth: "220px",
                                                margin: 0,
                                                backgroundColor: "#fff",
                                            }}
                                        >
                                            <ul
                                                style={{
                                                    border: "1px solid #ccc",
                                                    padding: "10px",
                                                    listStyle: "none",
                                                    maxWidth: "220px",
                                                    maxHeight: "150px", // Limit the height
                                                    overflowY: "auto", // Add vertical scroll
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                {filteredDataMob.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        style={{
                                                            cursor: "pointer",
                                                            padding: "5px",
                                                            borderBottom: filteredDataMob.length - 1 !== index ? "1px solid #eee" : "none",
                                                        }}
                                                        onClick={() => handleSelect(item)}
                                                    >
                                                        {item.custname} / {item.mobno} / {item.custstate}
                                                    </li>
                                                ))}
                                            </ul>

                                        </ul>
                                    ) : (
                                        searchTerm.trim() !== "" && (
                                            <div style={{ marginTop: "10px", color: "red" }}></div>
                                        )
                                    )}
                                    <br />
                                </div>

                    <div className='col-md-12 align-self-center text-end mt-2'>
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show </button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Sale Return Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid f-size-on-mobile' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center f-size-on-mobile' >
                        <tr >
                            <th className='text-start' scope="col">Lot No</th>
                            <th className='text-start' scope="col">Date</th>
                            <th className='text-start' scope="col">Cust Mob</th>
                            <th className='text-start' scope="col">Cust Name</th>
                            <th className='text-start' scope="col">Cust Add</th>
                            <th className='text-start' scope="col">Cust State</th>
                            <th className='text-start' scope="col">Style</th>
                            <th className='text-start' scope="col">Size</th>
                            <th className='text-end' scope="col">Pcs</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res.billno === data[x - 1].billno;
                            return (
                                <tr key={x}>
                                    <td className='text-start'>{isDuplicate ? '' : res.billno}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res.billdate ? new Date(res.billdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res.mobno}</td>
                                    <td className='text-start'>{res.custname}</td>
                                    <td className='text-start'>{res.custadd}</td>
                                    <td className='text-start'>{res.custstate}</td>
                                    <td className='text-start'>{res.style}</td>
                                    <td className='text-start'>{res.size}</td>
                                    <td className='text-end'>{res.salestock*-1}</td>
                                </tr>
                            );
                        })}

                        <tr class="table-info f-size-on-mobile">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><b>Total :</b></td>
                            <td className='text-end'><b>{data.reduce((total, res) => total + parseFloat(Number(res.salestock*-1)), 0)}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Sale Return Report</Text>
                    <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Lot No</Text>
                            <Text style={styles.headerCell}>Date</Text>
                            <Text style={styles.headerCell}>Cust Mob</Text>
                            <Text style={styles.headerCell}>Cust Name</Text>
                            <Text style={styles.headerCell}>Cust Add</Text>
                            <Text style={styles.headerCell}>Cust State</Text>
                            <Text style={styles.headerCell}>Style</Text>
                            <Text style={styles.headerCell}>Size</Text>
                            <Text style={styles.headerCell}>Pcs</Text>
                        </View>
                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res.billno === data[x - 1].billno;
                            return (
                                <View key={x} style={styles.tableRow}>
                                <Text style={styles.cell}>{isDuplicate ? '' : res.billno}</Text>
                                <Text style={styles.cell}>{isDuplicate ? '' : res.billdate ? new Date(res.billdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
                                <Text style={styles.cell}>{isDuplicate ? '' : res.mobno}</Text>
                                <Text style={styles.cell}>{res.custname}</Text>
                                <Text style={styles.cell}>{res.custadd}</Text>
                                <Text style={styles.cell}>{res.custstate}</Text>
                                <Text style={styles.cell}>{res.style}</Text>
                                <Text style={styles.cell}>{res.size}</Text>
                                <Text style={styles.cell}>{res.salestock*-1}</Text>
                            </View>
                            );
                        })}
                        <View style={styles.tableRow}>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>Total :</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.salestock*-1)), 0)}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});
export default Rpt_SaleReport_Return