import React, { useEffect, useRef, useState } from 'react'
import hostlink from '../Hostlink/hostlink';

import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'react-modal';
import Loading from './Loading';
const FinshingEntry = () => {
    const [isLoading, setIsLoading] = useState(false);
    const roomCodeRef = useRef(null);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    const scrollRef = useRef(null);
    const today = new Date();
    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
    const [editDeleteAPi, seteditDeleteAPi] = useState('');
    const [query, setQuery] = useState('');
    const [data, setData] = useState([]);
    const [LotNo, setLotNo] = useState([]);
    const [LotStyle, setLotStyle] = useState([]);
    const [LotLotSize, setLotSize] = useState([]);
    const [FebData, setFebData] = useState([])
    const [WData, setWData] = useState([])
    const [SortData, setSortData] = useState([])
    const [srvdata, setsrvData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [listVisible, setListVisible] = useState(false);
    const [isEditModenew, setisEditModenew] = useState(false);
    const [isEditModenewshopvno, setisEditModenewshopvno] = useState(0);
    const inputRef = useRef(null);
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp' ) {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].billno);
            if(LastMaster==''){
                setLastMaster(filteredData[selectedIndex].finisharname);
            }else if(LastMaster==filteredData[selectedIndex].finisharname){
    
            }else{
                toast.error("Pressman changed Please Check Lot No..")
                return false;
            }
            setitemdetail({
                ...itemdetail,
                billno: filteredData[selectedIndex].billno,
                style: filteredData[selectedIndex].style,
                size: filteredData[selectedIndex].size,
                pcs: filteredData[selectedIndex].stkqty,
                finisharcode:filteredData[selectedIndex].finisharcode,
                finisharname:filteredData[selectedIndex].finisharname
            });
            document.getElementById("pcs").focus();
        }
    };
    const handleItemClick = (index) => {
        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].billno);
        if(LastMaster==''){
            setLastMaster(filteredData[index].finisharname);
        }else if(LastMaster==filteredData[index].finisharname){

        }else{
            toast.error("Pressman changed Please Check Lot No..")
            return false;
        }
        setitemdetail({
            ...itemdetail,
            billno: filteredData[index].billno,
            style: filteredData[index].style,
            size: filteredData[index].size,
            pcs: filteredData[index].stkqty,
            finisharcode:filteredData[index].finisharcode,
            finisharname:filteredData[index].finisharname

        });
        document.getElementById("pcs").focus();
    };
    const fetchData = async () => {
        const response = await fetch(`${hostlink}/fatchdata/${usershopid}/3/billno`);
        const jsonData = await response.json();


        const responseStock = await fetch(`${hostlink}/PurchaseMasSTKGodwnFabSTKWashFinish/${usershopid}`);
        const jsonDataStock = await responseStock.json();

        // Loop through jsonData and add stkqtyroll and stkqty from jsonDataStock where sortno matches
        jsonData.forEach((item) => {
            const stockItem = jsonDataStock.find(stock => 
                stock.purchaseMas.billno === item.billno && stock.purchaseMas.finisharcode === item.finisharcode
            );
            if (stockItem) {
                item.stkqty = stockItem.stkqty;
            }
        });

        setData(jsonData);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;
    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const printdata = async (e) => {
        try {
            const response = await axios.get(`${hostlink}/getchehinroomservicePrint/${usershopid}/${e}`);
            const printWindow = window.open('', '_blank');
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);
                const data = `1,${hostlink}/getchehinroomservicePrint/${usershopid}/${e},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }
                printWindow.document.write(printContent);
                printWindow.document.close();
            } else {
                console.error("Empty or invalid response received.");
                return;
            } printWindow.print();
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };

    const generatePrintContent = (data) => {
        console.log(data)
        const tableInfo = data.length > 0 && data[0] !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Room Name : ${data[0][0]}</p1></span>` : '';
        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 3in;}hr{width: 3in;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 3in;"><p1 style="display: block; margin: 0 auto;text-align: center;"> ROOM SERVICE </p1>
        <img style="display: block; margin: 0 auto;" src="./logo${usershopid}.png" alt="" />
        <h1 style="text-align: center;">${localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].cname : '0'}</h1><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: start; width:50%;">Ord No : ${data.length > 0 ? data[0][13] : ''}</p1>${tableInfo}</p1></span><span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Ord Date : ${data[0][7] ? new Date(data[0][7]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;">Ord Time : ${data[0][8] ? new Date(`2022-01-01T${data[0][8]}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</p1></span><table style="border-Top: 1px solid black; width: 3in;"><tr style="border-bottom: 1px solid black;"><td style="width: 10%;border-bottom: 1px solid black;">SN</td><td style="width: 80%;border-bottom: 1px solid black;">Item Name</td><td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td></tr>
          ${data.map((x, index) => `<tr style="width: 100%"><td style="width: 10%">${index + 1}</td><td style="width: 80%">${x[1]}</td><td style="width: 10%; text-align: end;">${x[2].toFixed(2)}</td></tr>`).join('')}</table><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: end; width:100%;">Total Qty : ${Number(data.reduce((total, resp) => total + parseFloat(resp[2]), 0)).toFixed(2)}</p1></span><hr style="borderTop: 1px solid black; width: 3in;" />      
        </div>`;
    };
    useEffect(() => {
        fetchData();
    }, []);

    const cmdEdit = (vno) => {
        setisEditModenew(true);
        setisEditModenewshopvno(vno);
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"

        axios.get(`${hostlink}/PurchaseMasEdit/${usershopid}/${vno}`)
            .then((resp) => {
                setitemdetailGrid(resp.data)
                setitemdetail({
                    ...itemdetail,
                    billno: resp.data[0].billno,
                    billdate: resp.data[0].billdate,
                    fabcode: resp.data[0].fabcode,
                    fabname: resp.data[0].fabname,
                    Washercode:resp.data[0].wcode,
                    Washername:resp.data[0].wname,
                    finisharcode:resp.data[0].finisharcode,
                    finisharname:resp.data[0].finisharname

                });
            })
    }
    const cmdDelete = (vno) => {
        
        axios.delete(`${hostlink}/PurchaseMasdelete/${usershopid}/${vno}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                    handleCloseModal(); 
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };

    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.billno && item.billno.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const saveroomservice = async (isprint) => {

        // document.getElementById("cmdsave").disabled = true

        if (finisharcode == 0) {
            toast.error("Please Select Pressman...")
            document.getElementById("cmdsave").disabled = false
            document.getElementById("fabname").focus();
            return false
        }
        
      

        if (itemdetailGrid.length === 0) {
            toast.error("Nothing to Save. Add Item...")
            document.getElementById("cmdsave").disabled = false
            return false
        }
        setIsLoading(true);
        for (let i = 0; i < itemdetailGrid.length; i++) {
            itemdetailGrid[i].id = '';
            itemdetailGrid[i].billdate = billdate;
            itemdetailGrid[i].shopid = usershopid;
            itemdetailGrid[i].entrytype = 4;
            itemdetailGrid[i].fabname = "";
            itemdetailGrid[i].fabcode = 0;
            itemdetailGrid[i].wname = "";
            itemdetailGrid[i].wcode = 0;
            itemdetailGrid[i].finisharname = finisharname;
            itemdetailGrid[i].finisharcode = finisharcode;
            itemdetailGrid[i].ittotal = 0;
            itemdetailGrid[i].mtr = 0;
            itemdetailGrid[i].sortno = "";
            itemdetailGrid[i].avg = "";
            itemdetailGrid[i].totpcs = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.godwstk), 0);
        }
        console.log(isEditModenewshopvno)
      
        if (isEditModenew == true) {
            await axios.post(`${hostlink}/PurchaseMasEditsave/${usershopid}/${isEditModenewshopvno}`, itemdetailGrid)
                .then((resp) => { toast.success("Save Sucess..");setIsLoading(false); setInterval(() => { window.location.assign("/FinshingEntry") }, 1000); })
                .catch((resp) => { console.log("Data Not Save..");setIsLoading(false);  })
        } else {
            await axios.post(`${hostlink}/PurchaseMas`, itemdetailGrid)
                .then((resp) => { toast.success("Save Sucess.."); setIsLoading(false); setInterval(() => { window.location.assign("/FinshingEntry") }, 1000); })
                .catch((resp) => { console.log("Data Not Save..");setIsLoading(false);  })
        }
    }
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.billno);
        setListVisible(false);
        setSelectedIndex(index);
    };
    const [itemdetail, setitemdetail] = useState({ avg: "", style: "", size: "", assortment:"",alterfab:"", alterwash:"",alterfinish:"",pcs: "", rawcode: 0,finisharname:"",finisharcode:0, washqty:0, Washername: "", Washercode: 0, rawname: "", barcode: "", sortno: "", billno: "", billdate: today.toISOString().split('T')[0], mtr: "", roll: "", qty: 1, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "" });
    const { rawcode, rawname, barcode, sortno, qty,washqty, avg, style, billno, finisharcode,finisharname,fabcode, size, alterfinish,alterwash,alterfab,assortment,pcs, billdate, mtr, roll, fabname,Washercode,Washername, rate, ittotal } = itemdetail;
    const [itemdetailGrid, setitemdetailGrid] = useState([]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [itemdetailGrid]);

    useEffect(() => {
        axios.get(`${hostlink}/PurchaseMas/4/${usershopid}/${fromdate}/${todate}`)
            .then((Response) => {
                setsrvData(Response.data)
                setitemdetailGridRefresh(false)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
    }, [itemdetailGridRefresh]);
    
    

    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end
    
            // Ensure the event target is an input element and supports selection range
            if (event.target instanceof HTMLInputElement && ['text', 'password', 'search', 'tel', 'url'].includes(event.target.type)) {
                event.target.setSelectionRange(0, event.target.value.length); // Select the input value
            }
        }
        
        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
            } else if (event.keyCode === 9) {
                interactiveElements[0].focus(); // Cycle back to the first element if Tab is pressed on the last element
            }
        }
    };
    


    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 3
    });
    const { fromdate, todate, status } = frmdata;
   
    const [guestdata, setguestdata] = useState({ c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "", c_Grcodename: "", c_GCHID: "" });
    const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID } = guestdata;
    const onChangevalueRoom = (e, f) => {
        const existingItem = Room.find(item => item[0] === f);
        if (existingItem) {
            console.log("find")

            const updatedItDetails = Room.map(roomdata => {
                if (roomdata[0] === f) {
                    setguestdata({ ...guestdata, c_GCHID: roomdata[1], c_Grcodename: f, c_Gname: roomdata[2], c_GMob: roomdata[3], c_GAdd: roomdata[4], c_Grcode: roomdata[5] })
                    toast.success("Conform Guest Detail..");
                    document.getElementById("inputname1111").focus();
                }
            });

        } else {
            setguestdata({ ...guestdata, c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "" })
            toast.error("Select Room");
        }
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setitemdetail({ ...itemdetail, [name]: sanitizedValue });
    }

    const onChangevalueFab = (fabcode, fabname) => {
        setitemdetail({
            ...itemdetail,
            finisharcode: fabcode,
            finisharname: fabname
        });
    };

    const onChangevalueWasher = (Washercode, Washername) => {
        setitemdetail({
            ...itemdetail,
            Washercode: Washercode,
            Washername: Washername
        });
    };

    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const [isVisible, setIsVisible] = useState(false);

    const [Room, setRoom] = useState([])
    const [items, setItems] = useState([]);
    const handleDoubleClick = (itemId) => {
        const selectedItem = items.find(item => item.id === itemId);
        if (selectedItem) {
            alert(`Double-clicked on: ${selectedItem.billno}`);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    // Show button when user scrolls down 400px
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }

        axios.get(`${hostlink}/PurchaseMasFindSortNo/${usershopid}`)
            .then((Response) => {
                setSortData(Response.data);
            })
            .catch((Response) => { "data Not Found" })

        axios.get(`${hostlink}/${usershopid}/FinishingMasCont`)
            .then((Response) => {
                setFebData(Response.data);
            })
            .catch((Response) => { "data Not Found" })

            // axios.get(`${hostlink}/${usershopid}/WasherMas`)
            // .then((Response) => {
            //     setWData(Response.data);
            // })
            // .catch((Response) => { "data Not Found" })

        axios.get(`${hostlink}/fatchdata/${usershopid}/3/billno`)
            .then((Response) => {
                setLotNo(Response.data);
            })
            .catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/fatchdata/${usershopid}/3/style`)
            .then((Response) => {
                setLotStyle(Response.data);
            })
            .catch((Response) => { "data Not Found" })
        axios.get(`${hostlink}/fatchdata/${usershopid}/3/size`)
            .then((Response) => {
                setLotSize(Response.data);
            })
            .catch((Response) => { "data Not Found" })

        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);
    useEffect(() => {
        setitemdetail({
            ...itemdetail,
            avg: Number(Number(mtr) / Number(pcs)).toFixed(2)
        });
    }, [pcs])
    useEffect(() => {
        setitemdetail({
            ...itemdetail,
            avg: Number(Number(mtr) / Number(pcs)).toFixed(2)
        });
    }, [mtr])
    const handleDeleteItem = async (billno,style,size,godwstk) => {
        const updatedItDetails = itemdetailGrid.filter(item => item.billno !== billno || item.style !== style ||item.size !== size ||item.godwstk !== godwstk );
        toast.success("Item deleted");
        setitemdetailGrid(updatedItDetails);
        document.getElementById("inputname1111").focus();
    };

    const handleItemEdit = async (billno, style, size, washqty,alterfinish,alterwash,alterfab,assortment) => {
        setQuery(billno)
        setitemdetail({
            ...itemdetail,
            billno: billno.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
            style: style.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
            size: size.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
            pcs: washqty,
            alterfab: alterfab,
            alterfinish: alterfinish,
            alterwash: alterwash,
            assortment: assortment,
        });
    };
    const handleAddItem = () => {
        
        // if (finisharcode == 0) { toast.error("Please Select Finishar..."); document.getElementById("finisharname").focus(); return false }
        if (billno == 0) { toast.error("Please Enter Lot No..."); document.getElementById("inputname1111").focus(); return false }
        if (style == 0) { toast.error("Please Enter Style..."); document.getElementById("style").focus(); return false }
        if (size == 0) { toast.error("Please Enter Size..."); document.getElementById("size").focus(); return false }
        if (pcs == "") { toast.error("Please Enter Pcs..."); document.getElementById("pcs").focus(); return false }

         // Find the corresponding stock for the entered billno
         const stockItem = data.find(stock => stock.billno === billno); // Assuming `data` holds the stock information
         if (!stockItem) {
            //  toast.error("Stock not available for this Lot number");
            //  return false;
         }
     
         // Calculate total washqty already used in existing entries with the same billno
         let existingStock = 0;
     
         itemdetailGrid.forEach(item => {
             if (item.billno === billno) {
                 existingStock += Math.abs(item.godwstk);  // Using absolute value because it's negative in your case
             }
         });
     
         // Check if the new pcs would exceed available stock
         const totalPcs = existingStock + Number(pcs); // Calculating total pcs (washqty in your context)
     
         if (totalPcs > stockItem.stkqty) {
             toast.error(`Insufficient stock for Pcs. Available: ${stockItem.stkqty - existingStock}`);
            //  document.getElementById("pcs").focus(); 
            //  return false;
         }

        const existingItem = itemdetailGrid.find(item => 
            item.billno === billno && item.style === style && item.size === size
        );
        if (existingItem) {
            const updatedItDetails = itemdetailGrid.map(item => {
                if (item.billno === billno && item.style === style && item.size === size) {
                    return {
                        ...item,
                        billno: billno.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
                        style: style.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
                        size: size.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
                        pcs: 0,
                        washqty: 0,
                        finishqty: pcs*-1,
                        godwstk: pcs,
                        alterfab: alterfab,
                        alterfinish: alterfinish,
                        alterwash: alterwash,
                        assortment: assortment,
                    };
                }
                return item;
            });
            setitemdetailGrid(updatedItDetails);
            toast.success(`Lot no : ${billno}, Updated...`);
            setQuery('')
            document.getElementById("inputname1111").focus();
            setitemdetail({
                ...itemdetail,
                billno: "",
                style: "",
                size: "",
                pcs: "",
                washqty: "", 
                alterfab: "",
                alterfinish: "",
                alterwash: "",
                assortment: "",
            });
            return false;
            
        } else {
            setitemdetailGrid([...itemdetailGrid, {
                billno: billno.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
                style: style.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
                size: size.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '').trim(),
                pcs: 0,
                washqty: 0,
                finishqty: pcs*-1,
                godwstk: pcs,
                alterfab: alterfab,
                alterfinish: alterfinish,
                alterwash: alterwash,
                assortment: assortment,
                
                
            }])
            toast.success("Item added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                billno: "",
                style: "",
                size: "",
                pcs: "",
                washqty:"",
                godwstk:"",
                alterfab: "",
                alterfinish: "",
                alterwash: "",
                assortment: "",
            });
            document.getElementById("inputname1111").focus();
        }
    };
    const [LastMaster, setLastMaster] = useState('');
    useEffect(()=>{
        if(itemdetailGrid.length==0)
        {
            setLastMaster('');
            
        }
    },[itemdetailGrid])
    
    const cmdNew = () => {
        setisEditModenew(false);
        setisEditModenewshopvno(0);
        seteditDeleteAPi('')
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        console.log(searchValue)
        if (searchValue === "") {
            axios.get(`${hostlink}/PurchaseMas/4/${usershopid}/${fromdate}/${todate}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/PurchaseMasSearch/4/${usershopid}/${fromdate}/${todate}/${searchValue}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }
    return (


        <div>
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-2 p-1'>
                            <h5 className='font-monospace'><i class="fa-solid fa-check-to-slot"></i> Finshing</h5>
                        </div>
                        <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                            <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search Lot No" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                        </div>

                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>
                            <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Create New </button>
                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Bill Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">Pressman</th>
                                        <th scope="col" className="hide-on-mobile">Lot No</th>
                                        <th scope="col" className="hide-on-mobile">Tot Pcs</th>
                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Date :</b> </span> {res.billdate ? new Date(res.billdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {res[2] !== '' && (<>Lot No : {res.billno} <br /></>)}
                                                    {res[2] !== '' && (<>Pressman : {res.finisharname} <br /></>)}
                                                    <span className='text-bg-danger'>{res[0] !== '' && (<>Total Pcs : {res.totpcs} <br /></>)}</span>
                                                </div>
                                            </td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.finisharname}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.billno}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.totpcs}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                <Link to={"/FinshingEntry"} onClick={(e) => { cmdEdit(res.shopvno) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link> &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                                <Link className='btn btn-sm btn-danger' onClick={(e) => { handleOpenModal(res.shopvno, res.billno) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link> &nbsp;
                                                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                                                    <div><p>Are you sure you want to delete this <br /> Lot No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cmdDelete(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                                                </Modal>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Service */}
            <div id='newform' style={{ display: "none" }} className='f-size-on-mobile'>
                <div className='container-fluid py-1 '>
                    <div className='row py-1 shadow' style={{ backgroundImage: 'linear-gradient(to right, #1Ea5E0, #1Ea5E0, #1Ea5E0)', color: 'white' }}>
                        <div className="col-md-12 align-self-center"><h4> &nbsp;<i class="fa-solid fa-check-to-slot"></i> Finshing </h4></div>
                    </div>
                    <p></p>
                    <div className="row">
                        <div className="col-3 col-md-1 ">Date :</div>
                        <div className="col-12 col-md-2">
                            <input type="date" value={billdate} name='billdate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} className="form-control" id="billdate" />
                        </div>
                            <div className="col-3 col-md-1 ">Pressman :</div>
                            <div className="col-12 col-md-2">
                                <select id="fabname" onChange={(e) => {
                                        const selectedIndex = e.target.selectedIndex;
                                        const finisharcode = e.target.value;
                                        const finisharname = e.target.options[selectedIndex].text; onChangevalueFab(finisharcode, finisharname);}} value={itemdetail.finisharcode || 0} name="finisharname" className="form-select">
                                    <option key={0} value={0}>NA</option>{FebData.map((x) => (<option key={x.id} value={x.id}>{x.fname}</option>))}
                                </select>
                            </div>
                        {/* <div className="col-3 col-md-1 ">Washer :</div>
                        <div className="col-12 col-md-2">
                            <select id="Washername" onChange={(e) => {
                                    const selectedIndex = e.target.selectedIndex;
                                    const Washercode = e.target.value;
                                    const Washername = e.target.options[selectedIndex].text; onChangevalueWasher(Washercode, Washername);}} value={itemdetail.Washercode || 0} name="Washername" className="form-select">
                                <option key={0} value={0}>NA</option>{WData.map((x) => (<option key={x.id} value={x.id}>{x.wname}</option>))}
                            </select>
                        </div> */}
                    </div>
                    <p></p>
                    <div className="row f-size-on-mobile">
                        <div className="col-md-12 ">
                            <div className="row py-1">
                                <div className="col-6 col-md-3">
                                    <label className='font-monospace' class="form-label">Lot No :</label>
                                    <input type="text" placeholder="Search for items..." class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} autoComplete='off' /> <br />
                                    {listVisible && filteredData.length > 0 && (
                                        <ul style={{}}>
                                            {filteredData.map((item, index) => (
                                                <li
                                                    key={item.billno}
                                                    onClick={() => handleSelectItem(item, index)}
                                                    onMouseDown={() => handleItemClick(index)}

                                                    className={index === selectedIndex ? 'selected' : ''}
                                                >
                                                    {item.billno} / {item.finisharname} <span style={{ color: "red" }}>(St : {item.stkqty})</span> 
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                    {/* <select id="billno" onChange={(e) => { onChangevalue(e) }} value={billno} name='billno' class="form-select">
                                        <option selected key={0} value={0}>NA</option>{LotNo.map((x) => { return (<option key={x.id} value={x.billno}> {x.billno} </option>) })}
                                    </select> */}
                                </div>

                                <div className="col-6 col-md-3">
                                    <label className='font-monospace' class="form-label">Style</label>
                                    <select id="style" onChange={(e) => { onChangevalue(e) }} value={style} name='style' class="form-select">
                                        <option selected key={0} value={0}>NA</option>{LotStyle.map((x) => { return (<option key={x.id} value={x.style}> {x.style} </option>) })}
                                    </select>
                                </div>
                                <div className="col-6 col-md-3">
                                    <label className='font-monospace' class="form-label">Size</label>
                                    <select id="size" onChange={(e) => { onChangevalue(e) }} value={size} name='size' class="form-select">
                                        <option selected key={0} value={0}>NA</option>{LotLotSize.map((x) => { return (<option key={x.id} value={x.size}> {x.size} </option>) })}
                                    </select>
                                </div>

                                <div className="col-3 col-md-3">
                                    <label className='font-monospace' class="form-label">Pcs</label>
                                    <input type="number" value={pcs} name='pcs' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="pcs" /> <br />
                                </div>

                                <div className="col-3 col-md-3">
                                    <label className='font-monospace' class="form-label">Fin Alt</label>
                                    <input type="number" value={alterfinish} name='alterfinish' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="alterfinish" /> <br />
                                </div>
                                <div className="col-3 col-md-3">
                                    <label className='font-monospace' class="form-label">Wash Alt</label>
                                    <input type="number" value={alterwash} name='alterwash' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="alterwash" /> <br />
                                </div>
                                <div className="col-3 col-md-3">
                                    <label className='font-monospace' class="form-label">Fab Alt</label>
                                    <input type="number" value={alterfab} name='alterfab' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="alterfab" /> <br />
                                </div>
                                {/* <div className="col-3 col-md-2">
                                    <label className='font-monospace' class="form-label">Assortment</label>
                                    <input type="number" value={assortment} name='assortment' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="assortment" /> <br />
                                </div> */}

                                <div id='addroomNew' className="col-3 col-md-1 text-center align-content-start">
                                    <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                                    <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i></button>
                                </div>

                            </div>

                            <div className='show-on-mobile row   container' style={{ color: 'black' }}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-center shadow rounded align-self-center p-1 m-0 bg-light border border-1'>
                                        <p style={{ "color": "blue", borderBottom: "1px solid black" }}>Lot No : {resp.billno}</p>
                                        <div className='col-9 text-start' style={{ fontSize: "13px", color: "black" }}>
                                            <table style={{ "width": "100%" }}>
                                                <tr><td>Style :</td><td>: {resp.style}</td><td>Size :</td><td>: {(resp.size)}</td></tr>
                                                <tr><td>Pcs :</td><td>: {resp.godwstk}</td><td>Fin A :</td><td>: {(resp.alterfinish)}</td></tr>
                                                <tr><td>Was A :</td><td>: {resp.alterwash}</td><td>Fab A :</td><td>: {(resp.alterfab)}</td></tr>
                                                <tr><td>Ass :</td><td>: {resp.assortment}</td></tr>
                                            </table>
                                        </div>
                                        <div className='col-3 align-self-center'>
                                        <button type="submit" onClick={(e) => { handleItemEdit(resp.billno, resp.style, resp.size, resp.godwstk ,  resp.alterfinish,  resp.alterwash,  resp.alterfab,  resp.assortment, ) }} className="btn btn-sm btn-outline-dark"> <i className="fa-solid fa-pen-to-square"></i></button> &nbsp;
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.billno,resp.style,resp.size,resp.godwstk) }} className="btn btn-sm btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                                <div className='row text-left align-self-center p-1 m-0 '>
                                    <div className='col-md-2'>Lot No</div>
                                    <div className='col-md-3 text-end'>Style</div>
                                    <div className='col-md-1 text-end'>Size</div>
                                    <div className='col-md-1 text-end'>Pcs</div>
                                    <div className='col-md-1 text-end'>Fin A</div>
                                    <div className='col-md-1 text-end'>Was A</div>
                                    <div className='col-md-1 text-end'>Fab A</div>
                                    <div className='col-md-1 text-end'>Ass</div>
                                    <div className='col-md-1 text-end'>Action</div>
                                </div>
                            </div>
                            <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                                        <div className='col-md-2 align-self-center'>{resp.billno}</div>
                                        <div className='col-md-3 align-self-center text-end'>{resp.style}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.size}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.godwstk}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.alterfinish}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.alterwash}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.alterfab}</div>
                                        <div className='col-md-1 align-self-center text-end'>{resp.assortment}</div>
                                        <div className='col-md-1 align-self-center text-end'>
                                        <button type="submit" onClick={(e) => { handleItemEdit(resp.billno, resp.style, resp.size, resp.godwstk,  resp.alterfinish,  resp.alterwash,  resp.alterfab,  resp.assortment, ) }} className="btn btn-sm btn-outline-dark"> <i className="fa-solid fa-pen-to-square"></i></button> &nbsp;
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.billno,resp.style,resp.size,resp.godwstk) }} className="btn btn-sm btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    </div>
                                ))}
                                <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>
                                    <div className='col-md-8 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center text-start'><b>Total :</b></div>
                                    <div className='col-md-1 align-self-center  text-end'><b>{itemdetailGrid.reduce((total, resp) => total + parseFloat(Number(resp.godwstk)), 0)}</b></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 ">
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div className="parent-container">
                    {/* Fixed element at the bottom */}
                    <div className="fixed-bottom" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', borderTop: '1px solid black' }}>

                        <div className="container-fluid" >

                            <div className="row">
                                <div className="col-md-9 f-size-on-mobile text-end p-1" style={{ borderRight: "1px solid black" }}>
                                    <p className='hide-on-mobile m-2'></p>
                                    <button id='cmdsave' onClick={() => { saveroomservice(0) }} class="btn btn-primary">Save</button>  &nbsp;
                                    <a href='/FinshingEntry' class="btn btn-outline-primary">Cancel</a>
                                </div>
                                {/* Summary */}
                                <div className="col-md-3 f-size-on-mobile ">
                                    {/* <div className='rounded'>
                                        <div className="row p-1 m-1 f-size-on-mobile">
                                            <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '18px', fontWeight: 'bold', backgroundColor: "black", color: "white" }}> Total :</div> <div style={{ fontFamily: 'arial', backgroundColor: "black", color: "white", fontSize: '16px', fontWeight: 'bold' }} className="col-6 text-end"> {itemdetailGrid.reduce((total, resp) => total + parseFloat(Number(resp.rate) * Number(resp.mtr)), 0)} </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}

export default FinshingEntry
