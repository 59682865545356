import axios from 'axios';
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Settings = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [data, setData] = useState({})
    const { boxaname, boxbname } = data;

    const calcelForm = (e) => {
        window.location.assign("/")
    }

    const savedata = async () => {
        console.log(data)
        await axios.put(`${hostlink}/shopmas`, data)
            .then((responce) => {
                toast.success("Save Sucessfull")
                setInterval(() => {
                    localStorage.removeItem('MYPCKARTUSER');
                    window.location.assign("/")
                }, 1000);
            })
            .catch(() => {
                toast.error("Something went wrong, Please check it..")
            })
    }

    const onchange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        axios.get(`${hostlink}/shopmas/${usershopid}`)

            .then((resp) => {
                console.log(resp.data)
                setData(resp.data)
            })
            .catch(() => {
                console.log("Data Not Fatch...")
            })
    }, [1])

    return (
        <div>
            <div className='container p-1'>
                <h5 className='font-monospace text-danger'>System Configuration</h5>
                <div className='row'>
                    {/* Column 1 */}
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-6 '><label htmlFor="">Box 1 Name</label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='boxaname' value={boxaname} type="text" id='boxaname' /></div>

                            <div className='col-md-6 '><label htmlFor="">Box 1 Name</label></div>
                            <div className='col-md-4 '><input onChange={(e) => { onchange(e) }} name='boxbname' value={boxbname} type="text" id='boxbname' /></div>

                            <div className='col-md-6 '>
                            <br />
                            <button class="btn btn-primary" onClick={() => { savedata() }}>Save</button>  &nbsp;
                            <button class="btn btn-outline-dark" onClick={() => { calcelForm() }}>Cancel</button>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default Settings